import { configureStore } from '@reduxjs/toolkit'
import general from "./general"
import game from "./game"

export * from "./general"
export * from "./game"

const store = configureStore({
    reducer: { general, game },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: ['general/setModal'],
            ignoredPaths: ['general.modal'],
        }
    })
})

export default store