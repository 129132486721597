import { createSlice } from '@reduxjs/toolkit'

export const general = createSlice({
    name: 'general',
    initialState: {
        loading: false,
        loadingText: 'Loading...',
        modal: { isOpen: false, type: '', props: {} },
    },
    reducers: {
        setGeneralFields: (state, { payload }) => ({ ...state, ...payload }),
        setModal: (state, { payload }) => ({ ...state, modal: payload }),
    }
})

export const {
    setGeneralFields,
    setModal
} = general.actions

export default general.reducer;