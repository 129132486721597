import { default as Loading } from './Loading'
import { default as Underlined } from './Underlined'
import { default as Button } from './Button'

const Shared = {
    Loading,
    Underlined,
    Button
}

export default Shared