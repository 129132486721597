import { useSelector } from 'react-redux'
import { Lottie } from "@crello/react-lottie";
import Popup from 'reactjs-popup';
import * as animationData from "assets/animations/loading.json";
import "./styles.scss"

const Loading = () => {
    const { loading, loadingText } = useSelector(({ general }) => general)
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData.default,
        rendererSettings: { preserveAspectRatio: "xMidYMid slice" }
    };
    return <Popup
        modal
        className="modal-loading-container"
        open={loading}
        closeOnDocumentClick={false}
        closeOnEscape={false}
    >
        <div className="modal-loading-text">{loadingText}</div>
        <div className="modal-loading-animation">
            <Lottie config={defaultOptions} height={280} width={280} />
        </div>
    </Popup>
}

export default Loading