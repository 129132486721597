import Shared from "components/Shared"
import { database } from "config/firebase"
import { ref, update } from "firebase/database"
import { useQuery } from "hooks"
import Swal from "sweetalert2"
import { numberWithCommas } from "utilities/helpers"
import "./styles.scss"

const SellPosition = ({ handleSell, close, uid, change, value, newPrice, position }) => {

    const { offline } = useQuery()
    const { _id, type, name, quantity, lev } = position

    return <div className="modal-sell-position-container">
        <div className="icon icon-close" onClick={close} />
        <div className="modal-sell-position-header">
            <h2>Затваряне на позиция</h2>
            <p>{type === 'long' ? "Дълга" : "Къса"} {name}</p>
        </div>
        <div className="modal-sell-position-content">
            <div className="details-container">

                <div className="row">
                    <p>Количество: </p>
                    <span>{quantity}</span>
                </div>
                <div className="row">
                    <p>Промяна в цената (лот): </p>
                    <span>${numberWithCommas(newPrice)}</span>
                </div>
                <div className="row">
                    <p>Ливъридж: </p>
                    <span>{lev}</span>
                </div>
                <div className={`row row-profit ${change >= 0 ? "positive" : "negative"}`}>
                    <p>{change >= 0 ? "Печалба: " : "Загуба: "}</p>
                    <span>${numberWithCommas(change)}</span>
                </div>
            </div>
            <div className="row row-total">
                <h3>Възстановима сума: </h3>
                <span>${numberWithCommas(value)}</span>
            </div>

        </div>
        <div className="modal-sell-position-footer row">
            <Shared.Button
                text="Ликвидация"
                onClick={() => {
                    if (offline) handleSell({ position, change })
                    else update(ref(database), { [`/${uid}/positions/${_id}/profit`]: Number(change) })

                    close()
                    Swal.fire({
                        title: `Успешна ликвидация`,
                        text: "Вашата поръчка е изпълнена",
                        icon: "success",
                        confirmButtonColor: "#0099ff",
                        confirmButtonText: "Страхотно!"
                    })
                }}
                background="#00ce14"
            />
        </div>
    </div>
}

export default SellPosition