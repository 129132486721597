import { createSlice } from '@reduxjs/toolkit'

export const game = createSlice({
    name: 'game',
    initialState: { currentPeriod: -1 },
    reducers: {
        setGameFields: (state, { payload }) => ({ ...state, ...payload }),
        setNextPeriod: (state) => { state.currentPeriod = state.currentPeriod < 19 ? state.currentPeriod + 1 : state.currentPeriod },
    }
})

export const {
    setGameFields,
    setNextPeriod,
} = game.actions

export default game.reducer;