import Shared from "components/Shared";
import { database } from "config/firebase";
import { push, ref, set } from "firebase/database";
import { useQuery } from "hooks";
import { nanoid } from "nanoid";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { numberWithCommas } from "utilities/helpers";
import "./styles.scss"

const BuyPosition = ({ close, type, balance, name, lot, lev, price, handleBuy }) => {

    const { offline } = useQuery()
    const { uid } = useSelector(({ general }) => general?.user) || {}


    const [quantity, setQuantity] = useState(null)
    const maximum = useMemo(() => Math.floor((balance / (price * lot))), [balance, lot, price])

    return <div className="modal-buy-position-container">
        <div className="icon icon-close" onClick={close} />
        <div className="modal-buy-position-header">
            {type === 'long'
                ? <h2>Дълга позиция</h2>
                : <h2>Къса позиция</h2>
            }
            <p>Свободни средства: <span>${numberWithCommas(balance)}</span></p>
        </div>
        <div className="modal-buy-position-content">
            <div className="input-container">
                <div className="row">
                    <input
                        placeholder="Количество"
                        onChange={({ target: { value } }) => {
                            if (!/^\d+$/.test(value) && value !== '') return
                            if (Number(value) > maximum || Number(value) < 0) return
                            setQuantity(value)
                        }}
                        value={quantity || ''}
                    />
                    <Shared.Button onClick={() => { if ((quantity ?? 0) < maximum) setQuantity((quantity) => (quantity ?? 0) + 1) }} text={<div className="icon icon-plus" />} background="#0099ff" />
                    <Shared.Button onClick={() => { if ((quantity ?? 0) > 0) setQuantity((quantity) => (quantity ?? 0) - 1) }} text={<div className="icon icon-minus" />} background="#0099ff" />
                </div>
                <p>Максимално количество: <span>{maximum}</span></p>
            </div>
            <div className="details-container">
                <div className="row">
                    <p>Ед. цена (лот): </p>
                    <span>${numberWithCommas(price * lot)}</span>
                </div>
                <div className="row">
                    <p>Леверидж: </p>
                    <span>{lev} пъти</span>
                </div>
                <div className="row">
                    <p>Стойност на активите: </p>
                    <span>${numberWithCommas(quantity * price * lot * lev)}</span>
                </div>
            </div>
            <div className="row row-total">
                <h3>Ще платите: </h3>
                <span>${numberWithCommas(quantity * price * lot)}</span>
            </div>
        </div>
        <div className="modal-buy-position-footer row">
            <Shared.Button
                onClick={() => {
                    if (!quantity) return
                    const position = { type, name, price, quantity, balance, lot, lev, }
                    if (offline) handleBuy({ _id: nanoid(), ...position, })
                    else {
                        const postListRef = ref(database, uid + '/positions');
                        const newPostRef = push(postListRef);
                        set(newPostRef, position);
                    }

                    close()
                    Swal.fire({
                        title: `Успешна ${type === 'long' ? "покупка" : "продажба"}`,
                        text: "Вашата поръчка е изпълнена",
                        icon: "success",
                        confirmButtonColor: "#0099ff",
                        confirmButtonText: "Страхотно!"
                    })
                }}
                text={type === 'long' ? "Купи" : "Продай"}
                background={type === 'long' ? "#00ce14" : "#fa4460"}
            />
        </div>
    </div>
}

export default BuyPosition