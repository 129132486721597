import { signInWithEmailAndPassword } from "firebase/auth"
import { auth } from "config/firebase"
import { setGeneralFields, setModal } from "actions"
import { useDispatch } from "react-redux"
import "./styles.scss"
import Shared from "components/Shared"
import { useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import { useForm } from "react-hook-form"

const Login = ({ close }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { register, handleSubmit, formState: { errors } } = useForm()


    const handleLogin = async (payload) => {
        const { email, password } = payload || {}
        try {
            dispatch(setGeneralFields({ loading: true }))
            await signInWithEmailAndPassword(auth, email, password)
     
            close()
            navigate('/')
        }
        catch (error) { Swal.fire({ title: error.message, icon: 'error' }) }
        finally { dispatch(setGeneralFields({ loading: false })) }
    }

    return <div className="modal-login-container col">
        <div className="modal-login-header row">
            <h2>Вход</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        <div className="modal-login-form">
            <span>Имейл:</span>
            <input type="email" {...register("email", { required: true, pattern: /^\S+@\S+\.\S+$/ })} className={errors.email && "invalid"} />
            <span>Парола:</span>
            <input type="password" {...register("password", { required: true, minLength: 6 })} className={errors.password && "invalid"} />
        </div>

        <Shared.Button onClick={handleSubmit(handleLogin)} text="Продължи" background="#00ce14" />

        <p onClick={() => dispatch(setModal({ isOpen: true, type: 'ForgottenPassword' }))}>Забравена парола</p>

    </div>
}

export default Login