import { groupBy } from "lodash"
import { Fragment, useMemo } from "react"
import { percentChange, numberWithCommas } from "utilities/helpers"
import "./styles.scss"

const Instruments = ({ products, selectedProduct, prices, currentPeriod, setSelectedProduct, periods }) => {
    const productsGrouped = useMemo(() => {
        return Object.entries(groupBy(products?.map((p, i) => ({ ...p, _id: i })), 'Сектор') || {})
    }, [products])

    const handleCollapse = (elem, close) => {
        if (!elem) return

        if (!close) [...document.getElementsByClassName('expanded')].forEach((el) => el !== elem && handleCollapse(el, true))

        elem.style.height = ''
        elem.style.transition = 'none'
        const startHeight = window.getComputedStyle(elem).height
        elem.classList.toggle('expanded')
        const height = window.getComputedStyle(elem).height
        elem.style.height = startHeight
        requestAnimationFrame(() => {
            elem.style.transition = ''
            requestAnimationFrame(() => (elem.style.height = height))
        })
        elem.addEventListener('transitionend', () => (elem.style.height = ''))
    }

    return <div className="game-instruments-container">
        <h2>Инструменти</h2>
        <div className="game-instruments-content">
            {productsGrouped.map(([key, value]) => (
                <Fragment key={key}>
                    <div className="row row-group-header" onClick={() => handleCollapse(document.getElementById(key))}>
                        <h3>{key}</h3>
                        <div className="icon icon-arrow-down" />
                    </div>
                    <div className="group-container" id={key}>
                        {value?.map((p) => {
                            const change = percentChange({ name: p['Име'], prices, currentPeriod, periods })
                            return <div
                                key={p['Име']}
                                className={`row row-product ${selectedProduct === p._id && 'selected'}`}
                                onClick={() => setSelectedProduct(p._id)}
                            >
                                <p>{p['Име']}</p>
                                <div className="col col-price">
                                    <span>${numberWithCommas(prices?.[periods[currentPeriod]]?.[p['Име']]?.price)}</span>
                                    <span className={`change ${change > 0 ? 'positive' : change < 0 ? 'negative' : 'zero'}`} >{numberWithCommas(change)}%</span>
                                </div>
                            </div>
                        })}
                    </div>
                </Fragment>
            ))}
        </div>
    </div>
}

export default Instruments