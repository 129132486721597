import { useSelector, useDispatch } from 'react-redux'
import Popup from 'reactjs-popup';
import { setModal } from 'actions'
import 'reactjs-popup/dist/index.css';
import "./styles.scss"
import { Modals } from '.';


const Modal = () => {
    const { type, isOpen, props: allProps } = useSelector(({ general }) => general.modal)
    const dispatch = useDispatch()

    const props = { ...allProps, close: () => dispatch(setModal({ isOpen: false })) }

    const renderContent = () => {
        switch (type) {
            case 'Login':
                return <Modals.Login {...props} />
            case 'Register':
                return <Modals.Register {...props} />
            case 'ForgottenPassword':
                return <Modals.ForgottenPassword {...props} />
            case "BuyPosition": 
                return <Modals.BuyPosition {...props} />
            case 'SellPosition':
                return <Modals.SellPosition {...props} />
            default: 
                return null
        }
    }

    return <Popup
        modal
        className={`modal-container ${type}`}
        open={isOpen}
        onClose={() => {
            dispatch(setModal({ isOpen: false }))
            if (props?.onRequestClose) props.onRequestClose()
        }}
    >{renderContent()}</Popup>
}

export default Modal