import { setModal } from "actions"
import Shared from "components/Shared"
import EChartsReact from "echarts-for-react"
import { useMemo } from "react"
import { useDispatch } from "react-redux"
import { percentChange, numberWithCommas } from "utilities/helpers"
import "./styles.scss"

const Graph = ({ name, lot, lev, price, description, prices, currentPeriod, balance, handleBuy, periods }) => {
    const dispatch = useDispatch()
    const buyPositionProps = { balance, name, lot, lev, price, handleBuy }

    const data = useMemo(() => {
        return Object.entries(prices || {})
            .filter((_, i) => i <= currentPeriod)
            .reduce((acc, [_, value]) => [...acc, value?.[name]?.price], [])
    }, [currentPeriod, name, prices])

    const change = useMemo(() => percentChange({ name, prices, currentPeriod, periods }), [currentPeriod, name, periods, prices])

    return <div className="game-graph-container">
        <h1>{name} - ${numberWithCommas(price)} <p className={`change ${change > 0 ? 'positive' : change < 0 ? 'negative' : 'zero'}`} >{numberWithCommas(change)}%</p></h1>
        <h2>Ливъридж: {lev} - Лот: {lot}</h2>
        <p>{description}</p>
        <EChartsReact
            option={{
                grid: { top: 8, right: 8, bottom: 24, left: 36 },
                xAxis: { type: 'category', data: periods.slice(0, currentPeriod + 1) },
                yAxis: { type: 'value' },
                series: [{ data, type: 'line', smooth: true }],
                tooltip: { trigger: 'axis', valueFormatter: (value) => '$' + numberWithCommas(value) },
            }}
        />
        <div className="row row-buttons">
            <Shared.Button
                text={<><span className="icon icon-long" />Дълга</>}
                onClick={() => dispatch(setModal({ isOpen: true, type: 'BuyPosition', props: { type: 'long', ...buyPositionProps } }))}
                background="#00ce14"
            />
            <div style={{ width: 30 }} />
            <Shared.Button
                text={<><span className="icon icon-short" />Къса</>}
                onClick={() => dispatch(setModal({ isOpen: true, type: 'BuyPosition', props: { type: 'short', ...buyPositionProps } }))}
                background="#fa4460"
            />
        </div>
    </div>
}

export default Graph