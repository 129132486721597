export const percentChange = ({ name, prices, currentPeriod, periods }) => {
    if (currentPeriod === 0) return Number(0)
    if (currentPeriod === 20) currentPeriod = 19
    const last = Number(prices?.[periods[currentPeriod - 1]]?.[name]?.price || 0)
    const current = Number(prices?.[periods[currentPeriod]]?.[name]?.price || 0)
    const change = Number(((current - last) / last) * 100)
    return change;
}

export const positionChange = ({
    position: { type, name, price = 0, lot = 0, quantity = 0, lev = 0, profit },
    prices,
    currentPeriod,
    periods
}) => {
    if (![undefined, null].includes(profit)) return 0
    if (currentPeriod === 20) currentPeriod = 19
    const currentPrice = Number(prices?.[periods[currentPeriod]]?.[name]?.price || 0)
    return Number((type === "long" ? currentPrice - price : price - currentPrice) * lot * quantity * lev)
}

export const numberWithCommas = (number) => {
    return Number(number).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}