import { Modal, Header, Shared } from "components";
import { Routes, Route, Navigate } from "react-router-dom";
import {
    Home,
    Game,
    Admin
} from 'screens'
const RoutesComp = () => {

    return <>
        <Header />
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/game" element={<Game />} />
            <Route exact path="/admin" element={<Admin />} />
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <Modal />
        <Shared.Loading />
    </>
}

export default RoutesComp