import { setGameFields, setModal } from "actions"
import { Graph, Instruments, News, Portfolio } from "components"
import { CURRENT_YEAR, INITIAL_BALANCE, getPeriods } from "config/constants"
import { database } from "config/firebase"
import { child, get, onValue, ref, update } from "firebase/database"
import { useQuery } from "hooks"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { positionChange } from "utilities/helpers"
import "./styles.scss"

const Game = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { offline, year } = useQuery()
  const { currentPeriod } = useSelector(({ game }) => game)
  const { uid } = useSelector(({ general }) => general?.user) || {}
  const periods = useMemo(() => getPeriods(offline, year), [offline, year])

  useEffect(() => { if (offline && !year) navigate(`/game?offline=true&year=${CURRENT_YEAR}`) }, [navigate, offline, year])
  useEffect(() => { if (!offline && !uid) navigate('/') }, [offline, navigate, uid])

  useEffect(() => { dispatch(setModal({ isOpen: false })) }, [currentPeriod, dispatch])

  const [products, setProducts] = useState([])
  useEffect(() => {
    const data = localStorage.getItem(`products-${!offline ? CURRENT_YEAR : year}`)
    if (data) setProducts(JSON.parse(data))
    else get(child(ref(database), `/${!offline ? CURRENT_YEAR : year}/products`))
      .then((snapshot) => {
        const response = snapshot.val()
        setProducts(response)
        localStorage.setItem(`products-${!offline ? CURRENT_YEAR : year}`, JSON.stringify(response))
      })
  }, [offline, year])

  const [prices, setPrices] = useState({})
  const fetchPrices = useCallback((payload) => {
    const data = localStorage.getItem(`prices-${!offline ? CURRENT_YEAR : year}-${payload}`)
    if (data) setPrices((prices) => ({ ...prices, [payload]: JSON.parse(data) }))
    else get(child(ref(database), `/${!offline ? CURRENT_YEAR : year}/prices/${payload}`))
      .then((snapshot) => {
        const response = snapshot.val()
        setPrices((prices) => ({ ...prices, [payload]: response }))
        localStorage.setItem(`prices-${!offline ? CURRENT_YEAR : year}-${payload}`, JSON.stringify(response))
      })
  }, [offline, year])

  useEffect(() => {
    if (periods[currentPeriod]) fetchPrices(periods[currentPeriod])
  }, [currentPeriod, fetchPrices, periods])

  useEffect(() => {
    if (offline) dispatch(setGameFields({ currentPeriod: 0 }))
    else {
      const currentPeriodRef = ref(database, '/currentPeriod')
      const listener = onValue(currentPeriodRef, (snapshot) => {
        const currentPeriod = Number(snapshot.val())
        dispatch(setGameFields({ currentPeriod }))
        if (currentPeriod > 0) for (const i of Array(currentPeriod).keys()) fetchPrices(periods[i])
      })
      return () => { listener() }
    }
  }, [dispatch, offline, fetchPrices, periods])

  const [positions, setPositions] = useState([])
  useEffect(() => {
    if (uid && !offline) {
      const currentUserRef = ref(database, `/${uid}/positions`)
      const listener = onValue(currentUserRef, (snapshot) => {
        const positions = Object.entries(snapshot.val() || {})?.map(([key, value]) => ({ _id: key, ...value }))
        setPositions(positions)
      })
      return () => { listener() }
    }
  }, [dispatch, offline, uid])

  const [selectedProduct, setSelectedProduct] = useState(0)
  const {
    'Име': name,
    'Количество': lot,
    'Ливъридж': lev,
    'Описание': description,
  } = useMemo(() => products?.[selectedProduct] || {}, [products, selectedProduct])
  const { price } = useMemo(() => prices?.[periods[currentPeriod]]?.[name] || {}, [prices, periods, currentPeriod, name])

  const balance = useMemo(() => {
    return positions.reduce((acc, { price = 0, lot = 0, quantity = 0, profit }) => {
      if (![undefined, null].includes(profit)) return acc + profit
      else return acc - (price * lot * quantity)
    }, INITIAL_BALANCE)
  }, [positions])

  const margin = useMemo(() => {
    return positions.reduce((acc, { price = 0, lot = 0, quantity = 0, profit, lev = 0 }) => {
      if (![undefined, null].includes(profit)) return acc
      else return acc + (price * (lot * (quantity * ((lev - 1)))))
    }, 0)
  }, [positions])

  const profit = useMemo(() => {
    return positions.reduce((acc, position) => acc + Number(positionChange({ position, prices, currentPeriod, periods })) || 0, 0)
  }, [currentPeriod, periods, positions, prices])


  if (currentPeriod === -1) return <div className="screen-game-upcoming-container">
    <h2>Играта не е започнала!</h2>
  </div>
  if (currentPeriod === 20) return <div className="screen-game-finished-container">
    <h2>Играта приключи!</h2>
    <Portfolio
      {...{ balance, margin, profit, positions, prices, currentPeriod, periods }}
      handleSell={({ position, change }) => {
        if (offline) setPositions((positions) => positions.map((p) => position._id !== p._id ? p : { ...position, profit: Number(change) }))
        else update(ref(database), { [`/${uid}/positions/${position._id}/profit`]: Number(change) })
      }}
      hasFinished
    />
  </div>

  return <div className="screen-game-container">
    <Graph
      handleBuy={(position) => setPositions((positions) => [...positions, position])}
      {...{ name, lot, lev, price, description, prices, currentPeriod, balance, periods }}
    />
    <News {...{ currentPeriod, prices, name, periods }} />
    <Instruments {...{ products, selectedProduct, prices, currentPeriod, setSelectedProduct, periods }} />
    <Portfolio
      {...{ balance, margin, profit, positions, prices, currentPeriod, periods }}
      handleSell={({ position, change }) => {
        if (offline) setPositions((positions) => positions.map((p) => position._id !== p._id ? p : { ...position, profit: Number(change) }))
        else update(ref(database), { [`/${uid}/positions/${position._id}/profit`]: Number(change) })
      }}
    />
  </div>
}

export default Game