import React, { useCallback, useEffect, useRef } from "react"
import "./styles.scss"

const Underlined = ({ options = [], onChange = () => { }, className = '', disableTrack = false, disabled }) => {

  const selector = useRef(null)
  let linkDetails = useRef({})

  const detectRoute = useCallback((name) => {
    let currentLoc = linkDetails[name]
    if (currentLoc) {
      currentLoc.classList.add('underlined-tab-active')
      let left = currentLoc.offsetLeft
      let width = currentLoc.getBoundingClientRect().width
      selector.current.setAttribute('style', `left: ${left}px; width: ${width - 32}px;`)
      if (selector.current.offsetHeight === 0) setTimeout(() => { selector.current?.classList.add('selector-add-height') }, 200)
      onChange(name)
    }
  }, [onChange])


  useEffect(() => {
    detectRoute(options[0])
  }, [detectRoute, options])

  return <div className={`button-underlined-container ${className} ${disabled ? "disabled" : ""}`}>
    <div className="row">
      {options.map((el, i) => <div
        key={i}
        ref={ref => { linkDetails[el] = ref }}
        className='underlined-tab'
        onClick={() => detectRoute(el)}
      >
        {el}
      </div>)}
    </div>
    <div className={`row active-underlined-selector-container ${disableTrack ? "disable-track" : ""}`}>
      <div ref={selector} className='active-underlined-selector' />
    </div>
  </div>
}

export default Underlined