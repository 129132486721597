const { useState, useEffect } = require("react");

export const useLocalStorage = (key, defaultValue) => {
    const [value, setValue] = useState(() => {
        try { return JSON.parse(localStorage.getItem(key) || String(defaultValue)); }
        catch (error) { return defaultValue; }
    });

    useEffect(() => { localStorage.setItem(key, JSON.stringify(value)); }, [key, value]);

    return [value, setValue];
};

export default useLocalStorage;