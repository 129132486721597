import { BrowserRouter } from 'react-router-dom'
import { Routes } from 'components'
import { auth } from 'config/firebase';
import { useDispatch } from 'react-redux';
import { setGeneralFields } from 'actions';
import { onAuthStateChanged } from 'firebase/auth';
import { pick } from 'lodash';
const App = () => {
    const dispatch = useDispatch()
    onAuthStateChanged(auth, (user) => {
        if (user) dispatch(setGeneralFields({ user: pick(user, ['displayName', 'email', 'uid']) }))
        else dispatch(setGeneralFields({ user: undefined }))
    })

    return <BrowserRouter><Routes /></BrowserRouter>
}

export default App;