import { default as Login } from './Login'
import { default as Register } from './Register'
import { default as ForgottenPassword } from './ForgottenPassword'
import { default as BuyPosition } from './BuyPosition'
import { default as SellPosition } from './SellPosition'

export const Modals = {
    Login,
    Register,
    ForgottenPassword,
    BuyPosition,
    SellPosition,
}

export { default } from './Modal'