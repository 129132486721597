import GoogleMapReact from 'google-map-react';
import "./styles.scss"

const Home = () => {
    return <div className="screen-home-container col">
        <section className="main">
            <div className="image" />
            <h1>{`Събитието, целящо да повиши\nфинансовата грамотност на младите`}</h1>
        </section>
        <section className="description col">
            <div className="icon icon-levcheto" />
            <h1>Какво е Левчето?</h1>
            <p>Левчето е еднодневен финансов форум, който цели да повиши финансовата грамотност на младите хора в България. На събитието ежегодно се събират професионалисти от финансовата сфера, които изнасят кратки лекции по теми като икономика и лични финанси. След това се сформира панелна дискусия, на която аудиторията може да задава конкретни въпроси към лекторите.</p>
            <p>Кулминацията на събитието е интерактивна игра, която представлява симулация на стокова борса. Всеки участник има шанса да търгува и инвестира с виртуални пари в период от 1 час и 20 минути, като всеки победител печели парична награда.</p>
            <p>Събитието събира младежи с интерес към инвестициите и финансите и им дава възможността да обсъдят актуални теми, да се запознаят с опитни хора от сферата и да обменят идеи.</p>
        </section>
        <section className="sponsors">
            {/* <h2>Домакин на събитието</h2>
            <div className="row">
                <div className="image" style={{ backgroundImage: `url(${require('../../assets/images/FinanceAcademy.png')})` }} alt='sponsor-1' />
            </div> */}
            <h2>Генерални спонсори</h2>
            <div className="row">
                <a href="https://frontpeak.com/" target="_blank" rel="noreferrer"><div className="image" style={{ backgroundImage: `url(${require('../../assets/images/FrontPeak.png')})` }} alt='sponsor-2' /></a>
                <a href="https://www.postbank.bg/bg-BG" target="_blank" rel="noreferrer"><div className="image" style={{ backgroundImage: `url(${require('../../assets/images/Postbank.jpg')})` }} alt='sponsor-3' /></a>
                <a href="https://www.bunq.com/" target="_blank" rel="noreferrer"><div className="image" style={{ backgroundImage: `url(${require('../../assets/images/BUNQ.png')})` }} alt='sponsor-4' /></a>
                <a href="https://wibf-bg.org/" target="_blank" rel="noreferrer"><div className="image" style={{ backgroundImage: `url(${require('../../assets/images/WIBF.png')})` }} alt='sponsor-5' /></a>
                <a href="https://www.linkedin.com/company/wealthlock/about/" target="_blank" rel="noreferrer"><div className="image" style={{ backgroundImage: `url(${require('../../assets/images/WealthLock.png')})` }} alt='sponsor-6' /></a>
            </div>
            <h2>Стратегически спонсори и партньори</h2>
            <div className="row">
                <a href="https://lifeholic.eu/" target="_blank" rel="noreferrer"><div className="image" style={{ backgroundImage: `url(${require('../../assets/images/LifeHolic.png')})` }} alt='sponsor-7' /></a>
                <a href="https://emasbooks.com/" target="_blank" rel="noreferrer"><div className="image" style={{ backgroundImage: `url(${require('../../assets/images/ЕМАС.png')})` }} alt='sponsor-8' /></a>
                <a href="https://www.gift-tube.com/" target="_blank" rel="noreferrer"><div className="image" style={{ backgroundImage: `url(${require('../../assets/images/GiftTube.png')})` }} alt='sponsor-9' /></a>
                <a href="https://foody.to/" target="_blank" rel="noreferrer"><div className="image" style={{ backgroundImage: `url(${require('../../assets/images/Foody.png')})` }} alt='sponsor-10' /></a>
                <a href="https://www.dmi.bg/" target="_blank" rel="noreferrer"><div className="image" style={{ backgroundImage: `url(${require('../../assets/images/DMI.png')})` }} alt='sponsor-11' /></a>
                <a href="https://www.noacoffee.com/en/" target="_blank" rel="noreferrer"><div className="image" style={{ backgroundImage: `url(${require('../../assets/images/NoaCoffee.png')})` }} alt='sponsor-12' /></a>
                <a href="https://www.uni-sofia.bg/index.php/bul/universitet_t/fakulteti/stopanski_fakultet" target="_blank" rel="noreferrer"><div className="image" style={{ backgroundImage: `url(${require('../../assets/images/SU.jpg')})` }} alt='sponsor-13' /></a>
            </div>
            <h2>Медийни партньори</h2>
            <div className="row">
                <a href="https://bnr.bg/sofia/post/102061850/kade-i-kak-da-se-ogramotim-finansovo" target="_blank" rel="noreferrer"><div className="image" style={{ backgroundImage: `url(${require('../../assets/images/BNR.png')})` }} alt='sponsor-14' /></a>
                <a href="https://m.economy.bg/bulgaria/view/59674/Predstoi-12-oto-izdanie-na-Levcheto" target="_blank" rel="noreferrer"><div className="image" style={{ backgroundImage: `url(${require('../../assets/images/EconomyBG.png')})` }} alt='sponsor-15' /></a>
            </div>
        </section>
        <section className="row timeline">
            <div className="col">
                <p>2012</p>
                <div className="dot"><div className="line" /></div>
                <p>Първо издание в Софийски Университет</p>
            </div>

            <div className="col">
                <p>2015</p>
                <div className="dot"><div className="line" /></div>
                <p>Първата онлайн симулация</p>
            </div>

            <div className="col">
                <p>2020</p>
                <div className="dot"><div className="line" /></div>
                <p>Рекорден брой посетители</p>
            </div>

            <div className="col">
                <p>2022</p>
                <div className="dot"><div className="line" /></div>
                <p>Юбилейно издание</p>
            </div>
            <div className="col">
                <p>2024</p>
                <div className="dot"><div className="line" /></div>
                <p>12-то издание</p>
            </div>
        </section>
        <section className="footer">
            <div className="place-date">
                <h2>Място на събитието: <span>Национален дом на науката и техниката</span></h2>
                <h2>Адрес: <span>ул. "Г.С.Раковски" № 108</span></h2>
                <h2>Дата и Час: <span>26 октомври 2024, 10:00 часа</span></h2>

                <div style={{ height: 400, width: '100%' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
                        defaultCenter={{ lat: 42.692523, lng: 23.326451 }}
                        defaultZoom={18}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) => {
                            new maps.Marker({
                                position: { lat: 42.692523, lng: 23.326451 },
                                map: map,
                                draggable: false,
                            });
                        }}
                    />
                </div>
            </div>
            <div className="row">
                <div className="image" style={{ backgroundImage: `url(${require('../../assets/images/AIESEC.png')})` }} alt='AIESEC' />
                <div className="row row-social">
                    <a href="https://www.facebook.com/aiesec.bg" target="_blank" rel="noreferrer"><div className="icon icon-facebook" /></a>
                    <a href="https://instagram.com/aiesecbulgaria?igshid=YmMyMTA2M2Y=" target="_blank" rel="noreferrer"><div className="icon icon-instagram" /></a>
                    <a href="https://www.linkedin.com/company/aiesecbulgaria/" target="_blank" rel="noreferrer"><div className="icon icon-linkedin" /></a>
                </div>
            </div>
        </section>
    </div>
}

export default Home