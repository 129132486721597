import { setGeneralFields } from "actions"
import { Shared } from "components"
import { auth } from "config/firebase"
import { sendPasswordResetEmail } from "firebase/auth"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import "./styles.scss"

const ForgottenPassword = ({ close }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { register, handleSubmit, formState: { errors } } = useForm()


    const handleSend = async (payload) => {
        const { email } = payload || {}
        try {
            dispatch(setGeneralFields({ loading: true }))
            await sendPasswordResetEmail(auth, email)

            close()
            navigate('/')
        }
        catch (error) { Swal.fire({ title: error.message, icon: 'error' }) }
        finally { dispatch(setGeneralFields({ loading: false })) }
    }

    return <div className="modal-forgotten-password-container col">
        <div className="modal-forgotten-password-header row">
            <h2>Забравена парола</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        <div className="modal-forgotten-password-form">
            <span>Имейл:</span>
            <input type="email" {...register("email", { required: true, pattern: /^\S+@\S+\.\S+$/ })} className={errors.email && "invalid"} />
        </div>
        <Shared.Button onClick={handleSubmit(handleSend)} text="Изпрати имейл" background="#00ce14" />

    </div>
}

export default ForgottenPassword