import "./styles.scss"

const Button = ({
    text = "",
    onClick = () => { },
    disabled = false,
    background = '#ffb612',
    className = ""
}) => {
    return <button
        disabled={disabled}
        onClick={onClick}
        className={`shared-button-container ${className}`}
        style={{ backgroundColor: background }}
    >
        {text}
    </button>
}

export default Button