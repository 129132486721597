import { setGeneralFields, setModal } from "actions"
import { Shared } from "components"
import { auth, database } from "config/firebase"
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth"
import { ref, update } from "firebase/database"
import { pick } from "lodash"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import "./styles.scss"

const Register = ({ close }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { register, handleSubmit, formState: { errors } } = useForm()


    const handleRegister = async (payload) => {
        const { firstName, lastName, email, password } = payload || {}
        try {
            dispatch(setGeneralFields({ loading: true }))
            const { user } = await createUserWithEmailAndPassword(auth, email, password)
            await updateProfile(auth.currentUser, { displayName: `${firstName} ${lastName}` })

            await update(ref(database), { [`/${user.uid}/`]: pick(user, ['displayName', 'email']) })

            dispatch(setGeneralFields({ user: pick(user, ['displayName', 'email', 'uid']) }))

            close()
            navigate('/')
        }
        catch (error) { Swal.fire({ title: error.message, icon: 'error' }) }
        finally { dispatch(setGeneralFields({ loading: false })) }
    }

    return <div className="modal-register-container col">
        <div className="modal-register-header row">
            <h2>Регистрация</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        <div className="modal-register-form">
            <div className="row">
                <div className="col">
                    <span>Име:</span>
                    <input {...register("firstName", { required: true, minLength: 2 })} className={errors.firstName && "invalid"} />
                </div>
                <div style={{ width: 10 }} />
                <div className="col">
                    <span>Фамилия:</span>
                    <input {...register("lastName", { required: true, minLength: 2 })} className={errors.lastName && "invalid"} />
                </div>
            </div>
            <span>Имейл:</span>
            <input type="email" {...register("email", { required: true, pattern: /^\S+@\S+\.\S+$/ })} className={errors.email && "invalid"} />
            <span>Парола:</span>
            <input type="password" {...register("password", { required: true, minLength: 6 })} className={errors.password && "invalid"} />
        </div>


        <Shared.Button onClick={handleSubmit(handleRegister)} text="Продължи" background="#00ce14" />
        <p className="existing-profile">Вече имаш профил? <span onClick={() => dispatch(setModal({ isOpen: true, type: 'Login' }))}>Влез!</span></p>
    </div>
}

export default Register

