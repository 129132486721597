import Shared from "components/Shared"
import { isObject, partition } from "lodash"
import { useMemo, useState } from "react"
import "./styles.scss"

const tabs = ['Сектори', 'Този инструмент', 'Всички инструменти']

const News = ({ currentPeriod, prices, name, periods }) => {
    const [selectedTab, setSelectedTab] = useState(tabs[0])

    const [sectors, news] = useMemo(() => {
        return partition(
            Object.entries(prices?.[periods[currentPeriod]] || {}),
            ([_, value]) => !isObject(value)
        )
    }, [currentPeriod, periods, prices])

    const productNews = useMemo(() => {
        return Object.entries(prices || {})
            ?.slice(0, currentPeriod + 1)
            ?.reduce((acc, [key, product]) => [...acc, { key, news: product?.[name]?.news }], [])
            ?.reverse()
    }, [currentPeriod, name, prices])

    const renderTab = () => {
        switch (selectedTab) {
            case tabs[0]:
                return <div className="game-news-sectors-container">
                    <p>{periods[currentPeriod]}</p>
                    {sectors?.map(([key, sector]) => (
                        <div className="row" key={key}>
                            <span className="product">{key}</span>
                            <span className="news">{sector}</span>
                        </div>
                    ))}
                </div>
            case tabs[1]:
                return <div className="game-news-product-container">
                    {productNews.map(({ key, news }) => (
                        <div className="row" key={key}>
                            <span className="product">{key}</span>
                            <span className="news">{news}</span>
                        </div>
                    ))}
                </div>
            case tabs[2]:
                return <div className="game-news-products-container">
                    <p>{periods[currentPeriod]}</p>
                    {news?.map(([key, { news }]) => (
                        <div className="row" key={key}>
                            <span className="product">{key}</span>
                            <span className="news">{news}</span>
                        </div>
                    ))}
                </div>
            default:
                return null
        }
    }


    return <div className="game-news-container">
        <h2>Новини</h2>
        <Shared.Underlined options={tabs} value={selectedTab} onChange={setSelectedTab} />
        <div className="game-news-content">{renderTab()}</div>
    </div>
}

export default News